import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';
import Logo from '../images/mitsuru-mains-ds.svg';
import Eyes from '../images/smitseyes.png';
import MEyes from '../images/mitseyes.png';
import Kofi from '../images/kofi.png';

function Nav() {
  return (
    <nav className="nav">
      <div className="nav-img">
      <NavLink to="/">
        <img src={Logo} alt=""/>
      </NavLink>
      </div>
      <img src={MEyes} alt=""/>
      <div className="links">
        <div className="linker">
        <NavLink to="/mitsuruguide">
          Guide
        </NavLink>
        </div>
        <div className="linker">
        <NavLink to="/mitsurucombos">
          Combos
        </NavLink>
        </div>
        <NavLink to="/mitsuruvideos">
          Videos
        </NavLink>
        </div>
        <img src={Eyes} alt="" />
        <div className="links">
        <div className="linker">
        <NavLink to="/shadowguide">
          Guide
        </NavLink>
        </div>
        <div className="linker">
        <NavLink to="/shadowcombos">
          Combos
        </NavLink>
        </div>
        <NavLink to="/shadowvideos">
          Videos
        </NavLink>
    
      </div>
      <div className="socials">
        <a href="https://www.ko-fi.com/tloccpu">
          <img src={Kofi} alt="Buy me a kofi"/>
        </a>
        
      </div>

 
    </nav>
  )
}

export default Nav;