import React from 'react';
import "./ComboList.css";
import Combo from './Combo.jsx';
import Pagination from './Pagination.jsx';



function ComboList(props) {



  if (props.combos.length === 0) {
        return null;
    }
    
  let jsx = ( <h3>Choose a category to continue.</h3> );
  if (props.resetPage === true) {
    jsx = ( <Pagination
      data={props.combos}
      RenderComponent={Combo}
      title={props.title}
      pageLimit={props.combos.length}
      dataLimit={1}
    />);
  }

  return (
    <div className="combo-list">
        {jsx}

          
          
        {//props.combos.map((combo) => (<Combo key={combo.id} combo={combo}/>))
        }
        
    </div>
  )
}

export default ComboList;
