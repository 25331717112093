import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Guide.css';
import Mits from '../images/normalmitsuru.png';
import YoutubeEmbed from '../components/YouTube';
import twoD from '../images/twoD.png';
import bcoup from '../images/bcoup.png';
import jumpA from '../images/jumpA.png';
import jumpB from '../images/jumpB.png';
import fiveD from '../images/fiveD.png';

function MitsuruGuide() {
  return (
    
    <div className="guide">
    <h2>Normal Mitsuru Guide</h2>
    <img src={Mits} alt=""/>
    <Tabs>
    <TabList>
      <Tab>General Overview</Tab>
      <Tab>Neutral Game</Tab>
      <Tab>Pressure Theory</Tab>
      <Tab>Meter Usage</Tab>
    </TabList>

    <TabPanel>
      <h2>General Overview</h2>
      <p>This guide is meant for Mitsuru players who want to dig a little bit deeper past the beginner level and begin tackling the game at a deeper level. If you are a beginner, this might not be the best place to start.<br></br><br></br></p>
      
      <p>Mitsuru is a neutral heavy powerhouse whose overall strengths make her stand out in a way that sets her aside from most of the cast. Mitsuru is a charge character, which means her short list of specials require an input to be held. 
      There are no shortcuts to learning it, you simply have to commit to learning it.
      At the beginner and mid level, there is little to no difficulty in winning games with Mitsuru, but as you move on to the upper half of the skill pool, really intense mastery is required to get the most out of her and she becomes quite difficult to play.
      As a result, this is a character you expect to hit walls with, expect to have trouble figuring out what you're missing, and expect to spend a lot of time in training mode. 
      If you can brave that task, Mitsuru is insanely strong and will bring amazing results.<br></br><br></br></p>

      <p>
        Mitsuru is a Strike/Throw character, which means that rather than doing tricky high-low mixup to open up our opponent, we instead want to scare them into trying to escape with a jump or a button and punish them severely for it.
        The method of doing that is with throw setups, as throws cannot be teched on reaction in this game. Check out the pressure theory tab for a deeper dive on this.
      </p>

      <p>The normal version of Mitsuru has a few advantages over the shadow version, mainly in her autocombo, and also a few disadvantages. In order to decide which version you should play, please read my writeup on the major differences: <br></br><br></br>

      <q>SHOULD I PLAY REGULAR, OR SHADOW?<br></br>
This is the most common question asked since release. Let me start with this answer: If you are a beginner to this series, you should play regular. You should not play shadow. The amount you need to learn about the game to effectively play shadow is going to completely stunt your growth and you are far better off visiting this character later. With that said let's jump into the pros and cons!<br></br>
<br></br>
Regular<br></br>
+Consistent damage with meter<br></br>
+Hard knockdowns after almost all confirms<br></br>
+Regular autocombo is significantly stronger than shadow's for lockdown and strike/throw, her 5AA alone invalidates tons of characters and stops them from being able to function.<br></br>
+You can use your meter extremely liberally, and you can use SB Bufula for mixup and oki without worrying about much<br></br>
+Robbery potential almost as good as shadow mitsuru, but requires awakening (You will typically kill on any corner or launch confirm if you're in awakening, and you can kill on a standing confirm with OMB any time)<br></br>
<br></br>
-Can struggle to close out rounds due to standing confirm limitations<br></br>
-Is missing a plethora of standing launch confirms that only shadow mitsuru has access to<br></br>
<br></br>
<br></br>
Shadow<br></br>
+Can close out rounds from situations that will constantly surprise even yourself, possibly the best robbery potential in the entire game (shadow chie is pretty crazy too)<br></br>
+Unburstable 6k with only 50 meter, 7k+ with 100, works on ALMOST any confirm<br></br>
+Shadow's autocombo allows 5AA - 2[B] to launch a standing opponent, leading to more advantageous and high damaging cash-outs that normal mitsuru can't get<br></br>
<br></br>
! Meter and burst usage requires an extremely deep understanding of the character's potential and limitations. Besides just learning mitsuru and p4u as a whole, you will need to learn a lot of micro situations where you need to know how much meter a combo is going to generate, where you are on the screen, what the starter is for the combo, and which combo you need to use to optimize your burst and get a lot of it back for the next round, or choose to get almost none of it back and cash it all in. This aspect alone will require an extremely high amount of studying and learning with obviously very limited english resources, and if you skip it, you're just playing a low tier version of normal mitsuru. This cannot be underestimated and is the reason I think no beginner should touch this character. She is significantly harder than 1.1 shadow.<br></br>
<br></br>
-An execution failure with your shadow burst is basically throwing the match away, shadow mitsuru's damage without it is extremely lacking, especially if the opponent is in awakening.<br></br>
-Struggles a lot more with pinning characters down and getting close to the opponent compared to normal Mitsuru, requires a lot more risks on offense, however, usually only needs one or two successful mixups to win a round<br></br>
-Cannot use meter liberally, need to make very calculated decisions before pressing even one SB move to decide if it's worth it since you need your meter to kill <br></br>
</q>


      
      </p>
    </TabPanel>
    <TabPanel>
      <h2>Neutral Game</h2>
      <p>
        This section will be the same for both versions of Mitsuru, so with that in mind, let's jump into it.<br></br><br></br> </p>
      <h4>"Winning" Neutral</h4>
      <p>So what does it mean to "win" neutral? Bare with me, this is a concept commonly misunderstood. To start with, neutral implies that both characters are currently free to make their own decisions on movement, neither character is blocking or locked down. For the purposes of this guide, winning neutral means putting your opponent in a position where they will have to block Mitsuru's 5A or 2A, or receiving a large amount of damage from a combo or other means.
        This definition, as you'll imagine, covers a few different situations. <br></br>
      </p>
      <ul>
        <li>The opponent blocked an attack that put Mitsuru at block advantage, or, "plus on block", and now Mitsuru may follow up with a 5A or 2A.</li>
        <li>The opponent was hit by an attack and will tech with Mitsuru able to do a meaty 5A or 2A</li>
        <li>The opponent was hit by an attack, received a full combo, and has to deal with standard Mitsuru Oki</li>
      </ul>
      <p>If you understand that much, then it's time to talk about the attacks that put us in these situations.</p>
      <h4> The Big Three </h4>
      <p>Playing neutral with Mitsuru largely revolves around the usage of three of her attacks, used to match what you believe to be the pattern of your opponent. 
        I should note, this doesn't mean that these are the only moves you should ever use at neutral. 
        Moreso, these three moves are often the moves that will result in you winning neutral.</p>
      <h4>Jump A</h4>
      <img src={jumpA} alt=""/>
      <p>j.A (jump A) is probably the absolute best attack we have in the neutral game. 
        Beginners often feel that this attack is not very strong due to the low average reward, but this can be quite deceiving.
        On a low to ground hit or a counterhit, we can pick up a full combo with the general midscreen route(see the combo section). 
        On an air hit we can confirm with a jump cancel and do the air to air combo route.
        Besides the damage itself from getting a hit, the horizontal range on j.A is absolutely insane and beats almost every character in the game air to air outright with no chance to retaliate.
        Even if they block it, we're put at advantage and often "win" neutral here, allowing us to pressure. 
        We don't have to stop at just air to air either. A well placed landing j.A is about the same distance as a 5A and we end up beginning pressure that way extremely often due to how difficult it is to anti air at its max horizontal range.
        One thing you should be aware of is that j.A can be low profiled the same way as our 5A can. If your opponent is sharp they will try it, and if that happens, you'll need to switch to j.B, which obviously does not have as many neutral advantages.
         <br></br><br></br></p>

         <p>Since you've come to my guide and you have read this far, it's worth mentioning the deeper element of play here.
           It's not just about hitting them or making them block j.A, but instead, the long term game. An opponent who is losing in air to air situations will stop attempting air to air situations.
           This is a huge deal in p4u, as most characters best approaches are done in the air. A permanently grounded opponent is a dead opponent in the land of Mitsuru, and that's the kind of situation we want to create, but j.A isn't going to do it alone. Let's see what's next.
          <br></br>
         </p>
      <h4>2D</h4>
      <img src={twoD} alt=""/>
      <p>By far the most underused attack by new players, 2D is my 2nd ranked neutral game attack. This is a ground to air diagonal attack that reaches around 80% of the entire screen, and on hit, guarantees a full midscreen route combo(See combo page).
        Due to its range and where it hits, this move is an absolute thorn in the side for many characters, notable examples being Margaret(trying her air fireball), Kanji and Junpei (Air persona attacks for zoning/rushdown), Naoto (trying to set traps), and literally any character that is attempting to jump at you with a super jump.
        Now, why would an opponent opt for being at super jump height or being in the far corner out of range? Because you are using the almighty j.A in neutral. Like I've stated before, most characters best approaches for neutral are in the air, and if you're closing off air to air with j.A, they will naturally look for a new way to get around your commanding horizontal normal.
        Once this starts, 2D is the new king. One of my favorite things about this attack is how relatively safe it is to throw out. It has very short whiff recovery, generally beyond an opponent's reaction unless they're point blank (and you wouldn't press this if they are point blank anyway). This move is extremely difficult to deal with for most of the cast, and the reward is quite damning. Add it to your game ASAP.</p>   
      <br></br>
      <br></br>
      <p>With the air shut down, there's only one place left to cover, and it's what Mitsuru covers best, the ground level. Let's get into the last of our big three.<br></br></p>
      <h4>B Coup Droit</h4>
      <img src={bcoup} alt=""/>
      <p>With the air locked down and becoming a bad idea for our opponent, what we have left is Mitsuru's signature move, the coup droit. I should make you aware, A version in neutral is not something you will be doing(only to land punishes). This is about B version.
        In case you've been living under a rock, we have control over the distance B version travels by holding forward or back after releasing the button, this also helps us control the frame advantage from a blocked B coup droit. 
        The general rule is the farther Mitsuru is away from the opponent when B coup hits them on block, the more advantage frames she gets. In other words, a blocked B coup droit is a guaranteed win to the neutral game, and due to being able to pull mitsuru back during the animation, it's nearly impossible to low profile unless done at close range.
        We want to use B coup droit to stuff out opponents who try to use their ground options, or to catch a landing opponent, or to pressure an opponent we've conditioned to stay on the floor.
        When using B coup in your game, do keep in mind that you need to have enforced your anti air and air to air pretty well, and also keep in mind that you need to have a good grasp of how long it will take for the attack to connect as you weigh that against their current long range options.
        <br></br><br></br>
      </p>
      <h4>Honorable Mentions</h4>
      <img src={fiveD} alt=""/> <img src ={jumpB} alt=""/>
      <p>As mentioned before, the big three aren't necessarily the only moves you'll use at neutral, just generally the best options.
        5D and j.B(jump B) offer filler for situations you can't always cover with the big three. 5D for example can be thought of as a lower risk lower reward version of B coup droit. A blocked 5D is typically not going to gain you much and you'll usually reset to neutral, and it also whiffs on crouchers.
        That being said, an opponent trying to backdash or air backdash out of range or trying to jump over and stuff your B coup will not be successful in doing so, allowing you to use 5D to check your opponent where B coup is a much bigger commitment.
        Use it if you're not quite confident in your approach yet to feel out your opponent. On the rare occassions it hits, you do get the full general midscreen route as a combo(see the combo page). <br></br> <br></br>
      </p>
      <p>As for j.B, this move comes in handy for deeper layers of neutral. An experienced opponent will know they can low profile your coup droits, j.A, and 5A. 
        The safest way to deal with these high commital low profile options is to jump and choose j.B instead of j.A as you're falling. A counterhit j.B can be quite rewarding, so it is worth using. This, of course, comes at the cost of the huge loss of horizontal range that j.A covers, and it is susceptible to anti airs.

      </p>
      <h4>Closing Comments</h4>
      <p>By now you might be thinking, "We have really nice tools for neutral", and you're absolutely correct.
        Mitsuru's neutral is some of the best in the game and with her high damage output, winning neutral easily helps us win matches easily, it has a lot to do with why she's top tier in this game.
        You might have noticed that I didn't include A coup droit and that's because it's not great at neutral.
        Someone new to Mitsuru will likely use it a lot, but a blocked A coup droit results in your opponent being at advantage instead of you.
        In other words, A coup droit actually causes you to forfeit neutral rather than win it. 
        That's not to say it's worthless, A coup droit is extremely rewarding on counterhit, so you should be using it if you're specifically trying to land a counter hit or punish.
        <br></br><br></br>
      </p>
      <p>Above anything else, movement matters most. You might know the tools, but your movement in an anime fighter is the true skill behind neutral. If you're using all of these tools but you're not good at putting Mitsuru in the right place on the screen, you'll find even a character like Akihiko will beat you up.
        Take what you've learned from this section of my guide, and watch a player like YMST play and see if you can understand why they move the way they do. Good luck amigo. 
      </p>
    </TabPanel>
    <TabPanel>
        <h2>Pressure Theory</h2>
        <p>This is something I talked about at length on stream, so do feel free to check out the video. The Normal Mitsuru specific stuff is at the tail end, but I talk about general pressure the whole way through. Below the video, I'll write some notes, however, it won't be as detailed as the video.<br></br></p>
        <div className="player">
          <YoutubeEmbed embedId="https://www.youtube.com/embed/35EyVRaX11E" />
        </div>
        <h4>Normal Mitsuru 5AA, the Almighty</h4>
        <p>
          This is the standalone reason that Normal Mitsuru is worth playing and why Shadow Mitsuru isn't always the answer.
          Normal Mitsuru's 5A has an extremely long cancel time into 5AA that can be completely unpredictable. Alongside that, the 5AA into the 5AAA has the same long cancel time.
          5AA moves you forward a decent amount, can be hit confirmed extremely easily thanks to the cancel window, and has very little whiff recovery.
          This allows Normal Mitsuru to bully purely with her autocombo before setting up any of the layers I'll describe below. This version of 5AA is extremely powerful and identifies the whole character, in my opinion.
          It can't be overstated, if you're playing Normal Mitsuru, you should be spamming this. The later part of the video goes over it with a lot of examples, so do check it out.
        </p>
        <h4>Playing with 5B</h4>
        <p>After a blocked 5A, one of the best things we can string into is a 5B, or even better, a 5[B].
          5[B] can be dash canceled forward, and doing so is plus on block, whereas dash canceling a regular 5B is minus. 
          Since dash canceling a 5B or 5[B] allows us to get to point blank range, it forces a huge pseudo 50/50 situation on our opponent where they can attempt to tech or OS our throw and risk getting hit by 5A/2A, or they can attempt to block the 5A/2A and end up taking the throw. 
          Mitsuru gets nice damage on average off of throw thanks to the new cancel window, so this situation is quite dire for our opponent. The reason I call it a pseudo 50/50 is because the opponent can still use their DP in this sequence, so that's something to keep in mind. 
          An experienced opponent will know the dangers of 5B, and they will know that they can DP while it's winding up or even interrupt it with their own 5A. In order to cover this, you'll want to swap 5[B] with 5B, which can lead to a devestating fatal counter if they're looking to stuff your 5[B].
          <br></br>
        </p>
        <h4>Playing with 5C</h4>
        <p>
          5C is a tricky move to get acquainted with as you learn deeper Mitsuru play, but it's absolutely essential. Since it is considered a normal, it can be normal canceled into our B moves, sweep, 2X, and special canceled as well.
          Alongside of the array of cancels, it also can be both forward dash canceled and back dash canceled. The backdash is relatively safe, but the forward dash cancel is extremely minus and should only be used in special situations.
          At max range (such as after a 4B in your string), 5C will catch an opponent using their roll/quick escape, especially if you make use of the persona's position to backdash cancel your 5C into another one.
          If you do choose to do a 2nd 5C after a backdash cancel, your B coup droit will now be at a very ideal range(see the neutral game section to learn why that's a good thing).
          I like 5C a lot because it puts a lot of mental tension on our opponent and can mentally pressure them into taking some kind of action, whereas it's extremely easy for us to shut down options due to the versatility of cancels.
          A coup droit and D bufula will cover an opponent who wants to jump, backdash canceling into another covers roll and reversal attempts, Sweep can cover an opponent who is holding up back, and if you haven't used 5B yet you can even extend your pressure further.
          It's worth noting that one of the major weaknesses to 5C is that the longer you hold it, the less frame advantage it has on block.
          That's not to say you shouldn't hold it necessarily, as holding it creates a lot of tension, but you should be aware of how early your opponent can escape your followup the longer you wait.
          <br></br>
        </p>
        <h4>The Throw Game</h4>
        <p>
          Our two strongest options for setting up for a throw are a dash canceled 5[B] or a sweep feint at somewhat close range. Mitsuru is a strike/throw character and not a high/low character by default, so we tend to think more like a much more powerful grappler in our strings.
          An experienced opponent will use the option select or a retaliation if they have the frame advantage to stuff our throws. In this case, swap your throw attempt with a 2A(Check the combo page for the close range combo from 2A).
          Since the option select forces the opponent to stand, you will often catch your opponent low with the 2A, allowing you to enforce their defense in the direction you want.
          It's worth noting that an experienced opponent will generally try to avoid the grab, so make sure you understand your anti-jump options in your strings well using the concepts discussed above and in the video.
          <br></br>
        </p>
        <h4>Mr. Wizard's '2 Frame Overhead'</h4>
        <p>
          Perhaps the most infamous of our pressure tools is the rising j.B. This is an unreactable overhead that cannot be made safe without the use of 50 meter. Even on hit, you cannot combo unless you choose to OMC it. See the combo section for how that works. 
          This is the only high-low centerpiece we have in our strike/throw skillset, and due to the depth of Mitsuru's pressure, it is often forgotten leading to many rounds being stolen. 
          Keep this incredibly powerful option in your back pocket and watch how many rounds you seal even against the best opponents.
        </p>

    </TabPanel>
    <TabPanel>
        <h2>Meter Usage</h2>
        
        <p>Normal Mitsuru can be absolutely liberal on meter compared to her shadow counterpart, and thanks to the combo routing, gains meter at an extremely high rate.
          As shown in the combo section of this website, Normal Mitsuru gets a ton of damage out of using SB Coup Droit in corner combos and OMC on midscreen combos.
          Typically, the general rule is "If you have the meter, spend it." This means that you cannot be lazy about learning meter based conversions, especially midscreen OMC ones.
          Due to the lack of shadow frenzy, Normal Mitsuru needs to use the meter and her higher base damage to make up for it.
          I recommend always ending your general midscreen and corner routes with the AOA~D to maximize meter, and making sure to set up marin karin and cash in on meter every chance you get.
          When it comes to Mitsuru, there will always be more meter coming up. <br></br>
        </p>

        <h4>Awakening</h4>
        <p>
          Playing Normal Mitsuru also means planning to gain 50 meter for entering awakening. As such, we typically want to burst only if it will put us in Awakening unless we are dealing with a shadow combo that is going to kill us before then.
          Once in Awakening, Normal Mitsuru inherits Shadow Mitsuru's ability to use tentarafoo into mabufudyne into fully charged myriad out of the general midscreen and corner routes. This changes the way we consider our meter.
          If attempting to make a comeback, we will typically want to hold on to 100 meter during awakening any time it's possible. By being put into awakening, you should already be quite close to this amount, or will get it on the next combo.
          Once we have the 100 meter, we want to avoid using any unless we can for sure kill with a combo that would spend it or if our combo is going to put the opponent into Awakening and give them the defense buff.
          It is essential that you learn the mabufudyne cancel so that you can make these comebacks, as it's one of the big reasons Normal Mitsuru is top tier in a sea of shadows.
          Due to our ability to burst into Awakening and instantly gain 50 meter, Mitsuru is extremely tenacious and difficult to kill, as the danger of putting her in awakening makes her as strong as a shadow character. If you aren't taking advantage of this, you've picked the wrong character.
        </p>
    </TabPanel>
  </Tabs>
    </div>
  )
}


export default MitsuruGuide;