import { Routes, Route } from "react-router-dom"; 
import './App.css';
import Nav from './components/Nav.jsx';
import HomePage from './pages/HomePage.jsx';
import ShadowCombos from './pages/ShadowCombos.jsx';
import MitsuruCombos from './pages/MitsuruCombos.jsx';
import MitsuruMatchVideos from './pages/MitsuruMatchVideos.jsx';
import ShadowMatchVideos from './pages/ShadowMatchVideos.jsx';
import MitsuruGuide from './pages/MitsuruGuide.jsx';
import ShadowGuide from './pages/ShadowGuide.jsx';

function App() {
  return (
    
    <div className="App">
      <Nav/>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/shadowcombos' element={<ShadowCombos/>} />
        <Route path='/mitsurucombos' element={<MitsuruCombos />} />
        <Route path='/mitsuruvideos' element={<MitsuruMatchVideos />} />
        <Route path='/shadowvideos' element={<ShadowMatchVideos />} />
        <Route path='/mitsuruguide' element={<MitsuruGuide />} />
        <Route path='/shadowguide' element={<ShadowGuide />} />
      </Routes>  
      
    </div>  

  );
}

export default App;
