const MitsuruCombos = {
    fiveA: [
        {
          "title": "5A Midscreen Standing",
          "notation": "5AA > 6B > 5C > A Coup Droit",
          "description": "Regular BNB when not near any corner. This will be your main confirm from 5A when close to the opponent. Note that you hold 5C for 3 hits if you're farther away, and 6 if you're close.",
          "clip": "https://www.youtube.com/embed/ZZjM1lhRyqU",
          "key": 201
        },
        {
            "title": "5A Anti Air From Far Range ",
            "notation": "5AAA > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "Normal Mitsuru can pick up the general midscreen route on an airborne opponent with her autocombo into sweep, even from far range.",
            "clip": "https://www.youtube.com/embed/MFa39gvSQcU",
            "key": 207
        },
        {
          "title": "5A Standing Near/In Corner",
          "notation": "5AAA > B Coup Droit > SB Coup Doit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
          "description": "On a large portion of the cast, SB coup will combo after B coup allowing a corner pickup without starting in the corner. For the double bufula route, you'll need to immediately hold 2 for the next charge the instant you press 8C on the C bufula input.",
          "clip": "https://www.youtube.com/embed/SelaKU8lewM",
          "key": 202
        },
        {
            "title": "5A Standing Corner",
            "notation": "5AA > 6B > 2[B] > 2D > 5[B] > Sweep > B Coup Droit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
            "description": "Universal standing corner bnb for normal Mitsuru. You must be somewhat close for the 2[B] to reach. This will drop on most of the cast if they are crouching.",
            "clip": "https://www.youtube.com/embed/PGw1ZiPvDaA",
            "key": 203
        },
        {
            "title": "5A Anti Air Corner ",
            "notation": "5AAA > Sweep > B Coup > 2A > 2B > C Bufula > D Bufula > 6[B] > AOA~D",
            "description": "Mitsuru can pick up a corner combo meterless from an airborne opponent using her autocombo into sweep, even from far range.",
            "clip": "https://www.youtube.com/embed/CjQtkhJQMek",
            "key": 207
        },
        {
            "title": "5A Crouching",
            "notation": "5AA > 6B > Sweep > B Coup Droit",
            "description": "Simple, concise. You will confirm into this when you realize your opponent is crouching. You can get a safe jump IAD j.[B] after the combo. Without resources or corner, this is all you can get.",
            "clip": "https://www.youtube.com/embed/_8QQ_ApU4iY",
            "key": 204
        },
        {
            "title": "5A Crouching Corner",
            "notation": "5AA > 6B > Sweep > B Coup Droit > SB Coup Droit > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "Crouch confirm in the corner. This will be the general route for most corner combos that spend 25 meter regardless of starter. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. NOTE: IF FIGHTING A CHARACTER WHERE B COUP INTO SB COUP DOES NOT WORK, OMIT THE B COUP.",
            "clip": "https://www.youtube.com/embed/fEVURExfYtY",
            "key": 205
        },

        {
            "title": "5A Counter Corner",
            "notation": "5A (Counter) > Sweep > B Coup Droit > SB Coup Droit > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On counter hit, Mitsuru's 5A combos directly into sweep. This is very valuable as you will be checking at long range with 5A often in your gameplay. Once you get the Sweep, you follow the general corner route. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. NOTE: IF FIGHTING A CHARACTER WHERE B COUP INTO SB COUP DOES NOT WORK, OMIT THE B COUP.",
            "clip": "https://www.youtube.com/embed/aXruzQId1rE",
            "key": 206
        },
        {
            "title": "5A Standing 50 Meter ",
            "notation": "5AA > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "With 50 meter, mitsuru can combo 6B into 2[B] using a One More Cancel. After the launch you will use the general midscreen route. Use this if you have a lot of resources and need the extra damage or corner carry!",
            "clip": "https://www.youtube.com/embed/2V8qn1H-CWY",
            "key": 207
        },
        
      ],
    twoA: [
        {
            "title": "2A Standing",
            "notation": "2A > 2B > 6B > 5Cx6 > A Coup Droit ",
            "description": "Similar to the 5A standing confirm. This will be your optimal combo when baiting a jump or throw tech option select.",
            "clip": "https://www.youtube.com/embed/RzYZ7_bWQCA",
            "key": 208
        },
        {
            "title": "2A Crouching",
            "notation": "2A > 2B > 6B > Sweep > B Coup Droit ",
            "description": "Similar to the 5A crouch confirm. This will likely be your combo when punishing someone who is trying to low profile your 5A or abbare out of pressure.",
            "clip": "https://www.youtube.com/embed/4XrCxT5DeNU",
            "key": 209
        },

        {
            "title": "2A Standing Corner (Character Specific)",
            "notation": "1A > 1B > 4B > B Coup Droit > SB Coup Droit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On characters who can be hit by B Coup into SB Coup, we can get a full corner combo on a standing opponent.",
            "clip": "https://youtube.com/embed/17yXz9SD9xw",
            "key": 2256
        },

        {
            "title": "2A Counterhit Corner",
            "notation": "2A (Counter) > Sweep > B Coup Droit > SB Coup Droit > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "Same combo as from 5A counterhit. This will likely be your combo when punishing someone who is trying to low profile your 5A or abbare out of pressure in the corner.",
            "clip": "https://www.youtube.com/embed/aORdj_wYo_Q",
            "key": 210
        },
        {
            "title": "2A Standing 50 Meter",
            "notation": "2A > 2B > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > AOA~D",
            "description": "With 50 meter we can turn our 2A throw tech OS bait into a full corner carry combo. Note that you must omit the final 6[B] of the general midscreen route.",
            "clip": "https://www.youtube.com/embed/rv4JECLzoOA",
            "key": 211
        },
    ],
    
    fiveB: [
        {
            "title": "5B Fatal Uncharged Midscreen",
            "notation": "5B > 66 > 5AA > 6B > Sweep > B Coup Droit",
            "description": "Without resources, Mitsuru does not get much from an uncharged fatal at midscreen. Additionally, 5B > 66 is extremely unsafe and not recommended unless you know for sure it's going to hit. In practicality, you will probably not hit this in a match and instead punish with 2B or A Coup unless you can land a full charged fatal. Nevertheless, someone will be wondering, so here is the combo. Do not worry however, as we extend hard in the corner.",
            "clip": "https://www.youtube.com/embed/Df_rCUSV7Ho",
            "key": 212
        },
        {
            "title": "5B Fatal Charged Midscreen",
            "notation": "5[B] > 2[C] or Sweep Feint > 2[B] > 6[B] > 5Cx15 > 66 > 2B > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "With a fully charged 5B, we start to pop off. If you are not going to reach the corner in the first half of your hits, you should do this combo. We will hit 5[B] in a lot of situations. It punishes DPs, quick escape, and low profile attempts. Get used to doing this, it's where Mitsuru wins.",
            "clip": "https://www.youtube.com/embed/7PYJ0UZljQg",
            "key": 213
        },
        {
            "title": "5B Fatal Corner",
            "notation": "5[B] > 2[C] or Sweep Feint > 5[B] > Sweep > B Coup > 2A > 5Cx15 > 6[B] > 2[B] > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D > D Bufula > Tentarafoo > D Bufudyne > B Myriad Arrows > Level 2 Kick",
            "description": "Hitting this will instantly cause your opponent despair. You can choose sweep feint to bait a burst or 2[C] to generate meter. If you choose 2[C] you're very likely to have the meter for the double super ending here. 7K damage cash in without awakening.",
            "clip": "https://www.youtube.com/embed/zAFPWRbw4x8",
            "key": 214
        },

    ],

    twoB: [
        {
            "title": "2B Anti Air Combo",
            "notation": "2B > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
            "description": "General anti-air combo. The 2D~D allows you to confirm at most heights and you go into the general midscreen route after.",
            "clip": "https://www.youtube.com/embed/RYJNQ9xHShA",
            "key": 215
        },
        {
            "title": "2B Counterhit Safe Confirm",
            "notation": "2B > 7 > j.D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
            "description": "Since 2B is jump cancelable on block, we often use that jump cancel for additional mixup or to quickly block and bait something out. This is how you can pick up an unexpected counterhit during your safe play. The j.D will pick up pretty late, give it a try.",
            "clip": "https://www.youtube.com/embed/GAS2LwqzQAw",
            "key": 216
        },

    ],

    jumpB: [
        {
            "title": "Overhead Corner Starter",
            "notation": "j.B > 5AA > 6B > Sweep > B Coup Droit > SB Coup Droit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On an overhead starter such as j.B or j.A, the combo proration ends up too high to do the general corner route. Opt for this route instead on those starters. We'll have something a bit spicier for j.[B] so keep going ;o",
            "clip": "https://www.youtube.com/embed/2-rEoKy5HKA",
            "key": 217
        },
        {
            "title": "Retreating j.B Counterhit",
            "notation": "j.B (away from opponent) (counterhit) > j.D~D > Walk Forward > 5AAA > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "A very common counterhit trap that can lead into full damage. Do note that the 2[B] is extremely hard to space properly and depends on how close you hit with the autocombo. If it's too far skip the D bufula loop and go right into 6[B] AOA~D.",
            "clip": "https://www.youtube.com/embed/vpVRQ2rxSd0",
            "key": 217
        },
        {
            "title": "j.B Instant Overhead Midscreen",
            "notation": "j.B > OMC > j.[B] > j.C > C Bufula > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 5[B] > AOA~D",
            "description": "This is it, our biggest round winning clutch tool. This is Mitsuru's unreactable overhead and it costs 50 meter to attempt. Due to the new properties of j.[B], we can get a full launch combo at midscreen. Note that you can do this combo with a raw falling j.[B] as well. This does not work at farther ranges.",
            "clip": "https://www.youtube.com/embed/ETJJj7LRt7k",
            "key": 218
        },
        {
            "title": "j.B Instant Overhead Midscreen Far",
            "notation": "j.B > OMC > j.[B] > 5AA > 6B > Sweep > B Coup Droit ",
            "description": "On a fartherhit, we do not get much out of the instant overhead. Only worth going for if it's going to kill.",
            "clip": "https://www.youtube.com/embed/b-C3S4dTOeo",
            "key": 219
        },
        {
            "title": "j.B Instant Overhead Corner",
            "notation": "j.B > OMC > j.[B] > j.C > C Bufula > 5[B] > Sweep > B Coup > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "Using the same technique as midscreen, we can get a full combo here without the use of the extra 25 meter by using a j.[B] as the 2nd hit after OMC.",
            "clip": "https://www.youtube.com/embed/S9uO9PQcqo4",
            "key": 220
        },
        {
            "title": "j.B Instant Overhead Corner Far",
            "notation": "j.B > OMC > j.[B] > 5AA > 6B > Sweep > B Coup > SB Coup > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
            "description": "If we're too far away for the previous combo, we can still extend a full corner combo if we have 25 additional meter.",
            "clip": "https://www.youtube.com/embed/z5PBYhZmpL0",
            "key": 221
        },
    ],

    fiveC: [
        {
            "title": "5C Counterhit Midscreen",
            "notation": "5Cx15 (Counter) > 5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it.",
            "clip": "https://www.youtube.com/embed/VsWoMCrvUI0",
            "key": 223
        },
        {
            "title": "5C Counterhit Corner",
            "notation": "5Cx15 (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 5[B] > AOA~D",
            "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it for huge damage.",
            "clip": "https://www.youtube.com/embed/t-A6LY5neXs",
            "key": 224
        },
    ],
    
    jumpC: [
        {
            "title": "j.C Counterhit",
            "notation": "j.C (Counter) > 4B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "j.C is mostly used as a throw tech bait, but due to the option select, isn't used as often. However, j.C does not trigger things such as Kanji's counter DP so it double baits his throw and DP.",
            "clip": "https://www.youtube.com/embed/O6RPnUgv_6k",
            "key": 224
        },
    ],

    fiveD: [
        {
            "title": "5D Starter",
            "notation": "5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "Really solid low risk long range poke that can also be used as a safe meaty. Follows the general midscreen route.",
            "clip": "https://www.youtube.com/embed/gt3WW8aZfoM",
            "key": 225
        },
    ],

    twoD: [
        {
            "title": "2D Anti Air",
            "notation": "2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "This starter is amazing at neutral for catching early jump approaches or sniping zoners out of the sky. It follows the general midscreen route, nothing fancy.",
            "clip": "https://www.youtube.com/embed/n8SZVMhOMNE",
            "key": 222
        },
    ],

    sweep: [
        {
            "title": "Counterhit Sweep Corner",
            "notation": "Sweep (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On sweep counterhit, we can go into the general corner route without the use of any meter. Very huge for low profiling. If you confirm it late, you can spend the meter on SB coup for a last-moment confirm.",
            "clip": "https://www.youtube.com/embed/IZqJHUT3xIw",
            "key": 226
        },
        {
            "title": "Midscreen Sweep Confirm 50 Meter",
            "notation": "Sweep > A Coup > OMC > 5AAA > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "Sweep is historically one of Mitsuru's best starters. At midscreen, we can still get heavy reward off of a late sweet confirm using A coup and OMC. Thanks to the 2.0 changes, we can pick up autocombo with sweep for a full conversion.",
            "clip": "https://www.youtube.com/embed/XbSyAR30afA",
            "key": 226
        },
        {
            "title": "Throw Corner",
            "notation": "Throw > 5AA > 2[B] > 2D > 5[B]4 > Sweep > C Bufula > 5[B] > AOA~D",
            "description": "Optimized throw combo. Throw is high proration, so we shorten the general corner route.",
            "clip": "https://www.youtube.com/embed/CMbXGp8FXv8",
            "key": 227
        },
        {
            "title": "Counterhit Air Throw",
            "notation": "Air Throw > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > AOA~D",
            "description": "Much like our regular throw, our air throw prorates really hard. The initial throw itself, however, does tons of damage alone.",
            "clip": "https://www.youtube.com/embed/V2pQWT1hREE",
            "key": 226
        },
        {
            "title": "SB Bufula Early Tech",
            "notation": "SB Bufula (blocked) > j.B > j.C > 2[B] > 4[B] > Sweep > B Coup Droit > 2A > 2B > j.A > JC > j.A > j.B",
            "description": "This very unconventional combo will optimize damage on an early hit SB Bufula. The blocked j.C at the end is plus on block and covers forward tech.",
            "clip": "https://www.youtube.com/embed/V5GUlk5zON0",
            "key": 228
        },
        {
            "title": "SB Bufula Late Tech",
            "notation": "SB Bufula (2nd to last hit blocked) > j.B > 2[B] > 4[B] > Sweep > B Coup > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
            "description": "If you wait until the last hit of SB Bufula, or they tech late, you can do a much more advantageous and conventional corner route.",
            "clip": "https://www.youtube.com/embed/QEcRf_RLUmM",
            "key": 229
        },
    ],

    counter: [
        {
            "title": "A Coup Counterhit Standing Corner",
            "notation": "A Coup (Counter) > 66 > 5AA > 6B > 2[B] > 2D > 4[B] > Sweep > C Bufula > D Bufula > AOA~D",
            "description": "You can quickly dash after a counterhit A Coup and pick up a corner combo. The untechable time proration on A coup is not good, but the damage is high, so we shortern the route but still see large gains.",
            "clip": "https://www.youtube.com/embed/ifGQDCrQDzA",
            "key": 230
        },
        {
            "title": "A Coup Counterhit Crouching Corner",
            "notation": "A Coup (Counter) > 66 > 5AA > 6B > sweep > B Coup > SB Coup > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
            "description": "Our options are a bit more limited on a crouching hit, but we can still pick up a full corner combo with 25 meter. As always, omit the B Coup if it would drop vs the character you're fighting.",
            "clip": "https://www.youtube.com/embed/XHznlGgzwiA",
            "key": 230
        },
        {
            "title": "j.C Counterhit",
            "notation": "j.C (Counter) > 4B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "j.C is mostly used as a throw tech bait, but due to the option select, isn't used as often. However, j.C does not trigger things such as Kanji's counter DP so it double baits his throw and DP.",
            "clip": "https://www.youtube.com/embed/O6RPnUgv_6k",
            "key": 231
        },
        {
            "title": "5C Counterhit Midscreen",
            "notation": "5Cx15 (Counter) > 5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it.",
            "clip": "https://www.youtube.com/embed/VsWoMCrvUI0",
            "key": 232
        },
        {
            "title": "5C Counterhit Corner",
            "notation": "5Cx15 (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 5[B] > AOA~D",
            "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it for huge damage.",
            "clip": "https://www.youtube.com/embed/t-A6LY5neXs",
            "key": 233
        },
        {
            "title": "Counterhit Sweep Corner",
            "notation": "Sweep (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On sweep counterhit, we can go into the general corner route without the use of any meter. Very huge for low profiling. If you confirm it late, you can spend the meter on SB coup for a last-moment confirm.",
            "clip": "https://www.youtube.com/embed/IZqJHUT3xIw",
            "key": 234
        },
        {
            "title": "2B Counterhit Safe Confirm",
            "notation": "2B > 7 > j.D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
            "description": "Since 2B is jump cancelable on block, we often use that jump cancel for additional mixup or to quickly block and bait something out. This is how you can pick up an unexpected counterhit during your safe play. The j.D will pick up pretty late, give it a try.",
            "clip": "https://www.youtube.com/embed/GAS2LwqzQAw",
            "key": 235
        },
        {
            "title": "2A Counterhit Corner",
            "notation": "2A (Counter) > Sweep > B Coup Droit > SB Coup Droit > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "Same combo as from 5A counterhit. This will likely be your combo when punishing someone who is trying to low profile your 5A or abbare out of pressure in the corner.",
            "clip": "https://www.youtube.com/embed/aORdj_wYo_Q",
            "key": 236
        },
        {
            "title": "5A Counter Corner",
            "notation": "5A (Counter) > Sweep > B Coup Droit > SB Coup Droit > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
            "description": "On counter hit, Mitsuru's 5A combos directly into sweep. This is very valuable as you will be checking at long range with 5A often in your gameplay. Once you get the Sweep, you follow the general corner route. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. NOTE: IF FIGHTING A CHARACTER WHERE B COUP INTO SB COUP DOES NOT WORK, OMIT THE B COUP.",
            "clip": "https://www.youtube.com/embed/aXruzQId1rE",
            "key": 237
        },
    ],

    meter: [
        {
            "title": "2A Standing 50 Meter",
            "notation": "2A > 2B > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > AOA~D",
            "description": "With 50 meter we can turn our 2A throw tech OS bait into a full corner carry combo. Note that you must omit the final 6[B] of the general midscreen route.",
            "clip": "https://www.youtube.com/embed/rv4JECLzoOA",
            "key": 238
        },
        {
            "title": "5A Standing 50 Meter ",
            "notation": "5AA > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "With 50 meter, mitsuru can combo 6B into 2[B] using a One More Cancel. After the launch you will use the general midscreen route. Use this if you have a lot of resources and need the extra damage or corner carry!",
            "clip": "https://www.youtube.com/embed/2V8qn1H-CWY",
            "key": 239
        },
        {
            "title": "Midscreen 50 Meter Crouch Confirm Route ",
            "notation": "X > 5B > Sweep > OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "On crouch confirms that go into 5B, you can spend 50 meter to extend straight into the general midscreen route.",
            "clip": "https://www.youtube.com/embed/xK4en71muBs",
            "key": 240
        },
        {
            "title": "Midscreen 50 Meter Crouch Confirm Alternate ",
            "notation": "X > 5B > Sweep > OMC > 2[B] > 2D~D >  4[B] > Sweep > C Bufula > 6[B] > AOA~D",
            "description": "At some spacings, the previous combo will drop, this version sacrfices some damage in order to cover more spacings.",
            "clip": "https://www.youtube.com/embed/2hhA_oJ8Fto",
            "key": 241
        },
        {
            "title": "Midscreen Sweep Confirm 50 Meter",
            "notation": "Sweep > A Coup > OMC > 5AAA > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
            "description": "Sweep is historically one of Mitsuru's best starters. At midscreen, we can still get heavy reward off of a late sweet confirm using A coup and OMC. Thanks to the 2.0 changes, we can pick up autocombo with sweep for a full conversion.",
            "clip": "https://www.youtube.com/embed/XbSyAR30afA",
            "key": 226
        },
        {
            "title": "Awakening Corner Meter Dump",
            "notation": "Corner Combo > AOA~D > D Bufula > Tentarafoo > Mabufudyne > B Myriad Arrows (Fully Charged) > B Myriad Arrows (Fully Charged)",
            "description": "Normal Mitsuru can go into the famed shadow mitsuru sequence when in awakening. From any corner route, do this ender instead. In order for it to work, you must have only frozen the opponent twice before the AOA. Remember that 2D counts as a freeze! With 100 meter, you can do one full charged Myriad as well.",
            "clip": "https://www.youtube.com/embed/xwywiHbdG24",
            "key": 242
        },
        {
            "title": "Awakening Midscreen Meter Dump",
            "notation": "General Midscreen Route until D Bufula > Tentarafoo > Mabufudyne > B Myriad Arrows (Fully Charged) > B Myriad Arrows (Fully Charged)",
            "description": "Mitsuru has access to the famed shadow mitsuru sequence at midscreen as well. Instead of finishing the general midscreen route, cut it early with tentarafoo.",
            "clip": "https://www.youtube.com/embed/WUet7MQe5ow",
            "key": 243
        },
    ],

    OMB: [
        {
            "title": "Midscreen OMB Example",
            "notation": "",
            "description": "Mitsuru can use the OMB to convert a low damage standing confirm into a full midscreen route without using any meter. Use this if your opponent cannot retaliate with their own burst, and you have resources to kill such as in this example.",
            "clip": "https://www.youtube.com/embed/O0eksQUPX04",
            "key": 244
        },
        {
            "title": "Corner OMB Example",
            "notation": "",
            "description": "Mitsuru can use the OMB to convert a low damage standing confirm into a full corner route without using any meter. Use this if your opponent cannot retaliate with their own burst, and you can convert into large damage.",
            "clip": "https://www.youtube.com/embed/ROeYag4nruo",
            "key": 245
        },
    ]
    

}

export default MitsuruCombos;