const Videos = {
    Adachi: [
        {
            title: "7:43",
            clip: "https://embed.nicovideo.jp/watch/sm25690449?autoplay=0",
            key: 302
        }
    ],

    Aigis: [
        {
            title: "10:56",
            clip: "https://embed.nicovideo.jp/watch/sm26386773?autoplay=0",
            key: 301
        }
    ],

    AigisShadow: [
        {
            title: "3:31",
            clip: "https://embed.nicovideo.jp/watch/sm27341958?autoplay=0",
            key: 302
        },
        {
            title: "4:33",
            clip: "https://embed.nicovideo.jp/watch/sm25911370?autoplay=0",
            key: 302
        }
    ],

    Akihiko: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm27440956?autoplay=0",
            key: 302
        }

    ],

    AkihikoShadow: [
    ],

    Chie: [        {
        title: "10:37",
        clip: "https://embed.nicovideo.jp/watch/sm26237493?autoplay=0",
        key: 302
    }],

    ChieShadow: [        {
        title: "7:30",
        clip: "https://embed.nicovideo.jp/watch/sm27342205?autoplay=0",
        key: 302
    }],

    Elizabeth: [       
         {
        title: "7:38",
        clip: "https://embed.nicovideo.jp/watch/sm27199990?autoplay=0",
        key: 302
    }
    ],

    Junpei: [

    ],

    JunpeiShadow: [
        {
            title: "10:11",
            clip: "https://embed.nicovideo.jp/watch/sm26776511?autoplay=0",
            key: 302
        }
    ],

    Kanji: [
        {
            title: "10:27",
            clip: "https://embed.nicovideo.jp/watch/sm25572144?autoplay=0",
            key: 302
        }
    ],

    KanjiShadow: [

    ],

    Ken: [
 
    ],

    KenShadow: [

    ],

    Labrys: [

    ],

    LabrysShadow: [

    ],

    ShadowLabrys: [
        {
            title: "5:08",
            clip: "https://embed.nicovideo.jp/watch/sm27419968?autoplay=0",
            key: 302
        },
        {
            title: "first match",
            clip: "https://embed.nicovideo.jp/watch/sm26776625?autoplay=0",
            key: 302
        }
    ],

    Margaret: [
        {
            title: "Tahichi vs YMST long sets. Best players in the world on each character. Note: Shadow Mitsuru played here but still the best match to watch. Fight starts at 26:00",
            clip: "https://www.youtube.com/embed/hSjlrXo4ayw",
            key: 302
        }
    ],

    Marie: [
        {
            title: "first match",
            clip: "https://embed.nicovideo.jp/watch/sm25848745?autoplay=0",
            key: 302
        }
    ],

    Minazuki: [
        {
            title: "first match",
            clip: "https://embed.nicovideo.jp/watch/sm26223586?autoplay=0",
            key: 302
        }
    ],

    Sho: [

    ],

    MitsuruShadow: [
        {
            title: "6:17",
            clip: "https://embed.nicovideo.jp/watch/sm27440956?autoplay=0",
            key: 302
        }
    ],

    Naoto: [

    ],

    NaotoShadow: [
        {
            title: "4:10",
            clip: "https://embed.nicovideo.jp/watch/sm26530749?autoplay=0",
            key: 302
        }
    ],

    Narukami: [
        {
            title: "first match",
            clip: "https://embed.nicovideo.jp/watch/sm27419968?autoplay=0",
            key: 302
        }
    ],

    NarukamiShadow: [
        {
            title: "3:33",
            clip: "https://embed.nicovideo.jp/watch/sm26662415?autoplay=0",
            key: 302
        }
    ],

    Rise: [
        {
            title: "longer matches",
            clip: "https://embed.nicovideo.jp/watch/sm25643359?autoplay=0",
            key: 302
        }
    ],

    RiseShadow: [
        {
            title: "4:33",
            clip: "https://embed.nicovideo.jp/watch/sm26237649?autoplay=0",
            key: 302
        }
    ],

    Teddie: [
        {
            title: "8:58",
            clip: "https://embed.nicovideo.jp/watch/sm27225790?autoplay=0",
            key: 302
        }
    ],

    TeddieShadow: [
        {
            title: "7:36",
            clip: "https://embed.nicovideo.jp/watch/sm27200040?autoplay=0",
            key: 302
        }
    ],
    
    Yosuke: [

    ],

    YosukeShadow: [
        {
            title: "6:30",
            clip: "https://embed.nicovideo.jp/watch/sm26385129?autoplay=0",
            key: 302
        }
    ],

    Yukari: [
        {
            title: "3:54",
            clip: "https://embed.nicovideo.jp/watch/sm27635742?autoplay=0",
            key: 302
        }
    ],

    YukariShadow: [
        {
            title: "Long sets ft. Pepe Mitsuru, the winner of the first P4U SBO",
            clip: "https://www.youtube.com/embed/m-cxZplCr08",
            key: 302
        }
    ],

    Yukiko: [
        {
            title: "First match",
            clip: "https://embed.nicovideo.jp/watch/sm27199990?autoplay=0",
            key: 302
        }
    ],

    YukikoShadow: [
        {
            title: "first match",
            clip: "https://embed.nicovideo.jp/watch/sm26237493?autoplay=0",
            key: 302
        }
    ]

}

const VideosShadow = {
    Adachi: [],

    Aigis: [
        {
            title: "Maca (Mitsuru) vs. Maru (Aigis)",
            clip: "https://embed.nicovideo.jp/watch/sm26386773?autoplay=0",
            key: 301
        }
    ],

    AigisShadow: [],

    Akihiko: [
        {
            title: "Jon (Mitsuru) vs. Jomeshi (Akihiko)",
            clip: "https://embed.nicovideo.jp/watch/sm27440956?autoplay=0",
            key: 302
        }

    ],

    AkihikoShadow: [],

    Chie: [],

    ChieShadow: [],

    Elizabeth: [],

    Junpei: [],

    JunpeiShadow: [],

    Kanji: [],

    KanjiShadow: [],

    Ken: [],

    KenShadow: [],

    Labrys: [],

    LabrysShadow: [],

    ShadowLabrys: [],

    Margaret: [],

    Marie: [],

    Minazuki: [],

    Sho: [],

    MitsuruShadow: [],

    Naoto: [],

    NaotoShadow: [],

    Narukami: [],

    NarukamiShadow: [],

    Rise: [],

    RiseShadow: [],

    Teddie: [],

    TeddieShadow: [],
    
    Yosuke: [],

    YosukeShadow: [],

    Yukari: [],

    YukariShadow: [],

    Yukiko: [],

    YukikoShadow: []

}


export {Videos, VideosShadow};