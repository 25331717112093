import React from 'react';
import YoutubeEmbed from './YouTube';
import './Video.css';

function Video(props) {
    return (
        <div className="video">
            <div className="video-name">
                <h3>{props.combo.title}</h3>
            </div>
            <div className="clip">
                <YoutubeEmbed embedId={props.combo.clip} />
            </div>
          
        </div>
      )
}
export default Video;