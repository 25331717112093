import React from 'react';
import Pagination from './Pagination';
import Video from './Video';
import './VideoPlayer.css';

function VideoPlayer(props) {


    if (props.videos.length === 0) {
        return null;
    }
    
    return (
    <div className="video-pages">
    
        <Pagination
            data={props.videos}
            RenderComponent={Video}
            title={props.title}
            pageLimit={props.videos.length}
            dataLimit={1}
          />
        
    </div>
    )
}

export default VideoPlayer;