const ShadowMCombos = {
  fiveA: [
      {
        "title": "5A Midscreen Standing Far",
        "notation": "5AA > 6B > 5C > A Coup Droit",
        "description": "Regular BNB when not near any corner. This will be your main confirm from 5A when at a distance too great for 2B to reach. Note that you hold 5C for 3 hits if you're farther away, and 6 if you're close.",
        "clip": "https://www.youtube.com/embed/3SoSdjMzv1Y",
        "key": 201
      },
      {
        "title": "5A Midscreen Standing/Crouching Close",
        "notation": "5AA > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "Exclusive to Shadow Mitsuru. This is your bread and butter from 5A when near the opponent. It follows the general midscreen route post launch. 2DD is not necessary. Works on standing and crouching.",
        "clip": "https://www.youtube.com/embed/ytExPtwMrg0",
        "key": 260
      },
      {
        "title": "5A Midscreen Airborne Opponent",
        "notation": "5AA > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "Shadow Mitsuru is able to pick up 5AA on an airborne opponent even if they are at a low height. You will generally need the 2DD filler here due to different heights and character sizes, but follow the general midscreen route after.",
        "clip": "https://www.youtube.com/embed/Gr7V79bjY-s",
        "key": 261
      },
      {
        "title": "5A Near/In Corner",
        "notation": "5AA > 2[B] > 6[B] > Sweep > B Coup > 2A > 2B > 2D > 5[B]4 > Sweep C Bufula > 6[B] > AOA~D",
        "description": "Bread and butter from 5A when near the corner and near the opponent. This combo follows the general shadow corner route. You may opt to add the D bufula from Normal Mitsuru's general corner route (see her combo page for more) if you will not be using your meter for the combo ender after AOA~D.",
        "clip": "https://www.youtube.com/embed/yZSFd7A5pP0",
        "key": 202
      },
      {
          "title": "5A Crouching",
          "notation": "5AA > 6B > Sweep > B Coup Droit",
          "description": "Simple, concise. You will confirm into this when you realize your opponent is crouching and you are too far away for the 5A bread and butter. You can get a safe jump IAD j.[B] after the combo. Without resources or corner, this is all you can get.",
          "clip": "https://www.youtube.com/embed/hCAlIvo5q4g",
          "key": 204
      },
      {
          "title": "5A Crouching Corner",
          "notation": "5AA > 6B > Sweep > SB Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > 5[B] > AOA~D ",
          "description": "Crouch confirm in the corner. This will be the general route for most corner combos that spend 25 meter regardless of starter. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. As with normal Mitsuru, you can add a B coup before the SB coup for extra damage on certain characters.",
          "clip": "https://www.youtube.com/embed/aJZ_Y0E7Ts4",
          "key": 205
      },
      {
          "title": "5A Counter Corner",
          "notation": "5A (Counter) > Sweep > SB Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > 5[B] > AOA~D ",
          "description": "On counter hit, Mitsuru's 5A combos directly into sweep. This is very valuable as you will be checking at long range with 5A often in your gameplay. Once you get the Sweep, you follow the general corner route. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. As with normal Mitsuru, you can add a B coup before the SB coup for extra damage on certain characters.",
          "clip": "https://www.youtube.com/embed/_CMeM927G9k",
          "key": 206
      },

    ],
  twoA: [
      {
          "title": "2A Standing Close",
          "notation": "2A > 2B > 6B > 5Cx6 > A Coup Droit ",
          "description": "Similar to the 5A standing confirm. This will be your optimal combo when baiting a jump or throw tech option select.",
          "clip": "https://www.youtube.com/embed/UxE1WRWLN2E",
          "key": 208
      },


      
      {
          "title": "2A Far",
          "notation": "2AAA > A Coup Droit ",
          "description": "'That's it?' Yep.",
          "clip": "https://www.youtube.com/embed/O4hq8Pa9OrU",
          "key": 209
      },

      {
        "title": "2A Standing Corner (Character Specific)",
        "notation": "1A > 1B > 4B > B Coup Droit > SB Coup Droit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D ",
        "description": "On characters who can be hit by B Coup into SB Coup, we can get a full corner combo on a standing opponent.",
        "clip": "https://youtube.com/embed/CJv8RodB_8A",
        "key": 2255
      },
  ],
  
  fiveB: [
      {
          "title": "5B Fatal Uncharged Midscreen",
          "notation": "5B > 66 > 5AA > 2[B] > 6[B] > 5Cx15 > 66 > 2B > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > AOA~D",
          "description": "Unlike Normal Mitsuru, Shadow can get a full combo here. You'll do this on a guranteed punish only, and when you don't have enough time to fully charge 5[B].",
          "clip": "https://www.youtube.com/embed/orZ3JGn4d_I",
          "key": 212
      },
      {
          "title": "5B Fatal Charged Midscreen",
          "notation": "5[B] > 2[C] or Sweep Feint > 2[B] > 6[B] > 5Cx15 > 66 > 2B > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "f you are not going to reach the corner in the first half of your hits, you should do this combo. We will hit 5[B] in a lot of situations. It punishes DPs, quick escape, and low profile attempts. Get used to doing this, it's where Mitsuru wins.",
          "clip": "https://www.youtube.com/embed/HR7qnAqInog",
          "key": 213
      },
      {
          "title": "5B Fatal Corner",
          "notation": "5B(Counter) > Sweep > B Coup > 2A > 5Cx15 > 6[B] > 2[B] > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D",
          "description": "Hitting this will instantly cause your opponent despair. If you hit a charged 5[B] You simply do a 2nd 5[B] and go into this same route. In most cases, you will build 100 meter and can do mabufudyne > myriad ender for 7k without using frenzy.",
          "clip": "https://www.youtube.com/embed/cUP3WZ90WaY",
          "key": 214
      },

  ],

  twoB: [
      {
          "title": "2B Anti Air Combo",
          "notation": "2B > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
          "description": "General anti-air combo. The 2D~D allows you to confirm at most heights and you go into the general midscreen route after.",
          "clip": "https://www.youtube.com/embed/RYJNQ9xHShA",
          "key": 215
      },
      {
          "title": "2B Counterhit Safe Confirm",
          "notation": "2B > 7 > j.D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
          "description": "Since 2B is jump cancelable on block, we often use that jump cancel for additional mixup or to quickly block and bait something out. This is how you can pick up an unexpected counterhit during your safe play. The j.D will pick up pretty late, give it a try.",
          "clip": "https://www.youtube.com/embed/GAS2LwqzQAw",
          "key": 216
      },
      {
          "title": "2B Counterhit Feint Sweep",
          "notation": "2B > Feint Sweep > 5AA > 6B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
          "description": "Sometimes people are mashing and get counterhit by your 2B. This is a potential way you can still pick up that unpredictable counterhit for a full combo thanks to shadow mitsuru's fast 5AA.",
          "clip": "https://www.youtube.com/embed/7j7cPwi0iXQ",
          "key": 270
      },
      {
          "title": "2B Anti Air Corner",
          "notation": "2B > Feint Sweep > 5AA > 6B > Sweep > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D ",
          "description": "Similar to the previous, we can take advantage of Shadow Mitsuru's fast 5AA to pick up a corner combo off of a high anti air where a 2D pickup would be way too high.",
          "clip": "https://www.youtube.com/embed/_8ATgFJXE1U",
          "key": 275
      },
  ],

  jumpB: [
      {
          "title": "Jump-in Combo",
          "notation": "j.B > 66 > 5AA > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
          "description": "Both j.B and j.A combo into the 5A midscreen bread and butter. I recommend you do a microdash after landing before your 5A to close distance.",
          "clip": "https://www.youtube.com/embed/L1Sj3isEYlQ",
          "key": 217
      },
      {
        "title": "Charged j.[B] Combo",
        "notation": "j.[B] > j.C > C Bufula > 5[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
        "description": "Due to the changes to j.B in 2.0, we can now do this combo off of any close j.[B] hit as it forces crouch.",
        "clip": "https://www.youtube.com/embed/xCFLz0kBsJ8",
        "key": 271
    },
      {
          "title": "j.B Instant Overhead Midscreen",
          "notation": "j.B > OMC > j.[B] > j.C > C Bufula > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 5[B] > AOA~D",
          "description": "The all powerful Mr. Wizard '2 frame overhead'. Combo route follows same logic as the previous combo, but starts from the unreactable rising j.B",
          "clip": "https://www.youtube.com/embed/MC3pRv30-6c",
          "key": 218
      },
      {
          "title": "j.B Instant Overhead Midscreen Far",
          "notation": "j.B > OMC > j.[B] > 5AA > 6B > Sweep > B Coup Droit ",
          "description": "On a farther hit, we do not get much out of the instant overhead unless we spend frenzy. Only worth going for if it's going to kill.",
          "clip": "https://www.youtube.com/embed/b-C3S4dTOeo",
          "key": 219
      },
      {
          "title": "j.B Instant Overhead Corner",
          "notation": "j.B > OMC > j.[B] > j.C > C Bufula > 5[B] > Sweep > B Coup > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D ",
          "description": "Using the same technique as midscreen, we can get a full combo here without the use of the extra 25 meter by using a j.[B] as the 2nd hit after OMC.",
          "clip": "https://www.youtube.com/embed/Q3HaGtxsKyM",
          "key": 220
      },
  ],

  fiveC: [
      {
          "title": "5C Counterhit Midscreen",
          "notation": "5Cx15 (Counter) > 5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it.",
          "clip": "https://www.youtube.com/embed/6qTGP9yncN4",
          "key": 223
      },
      {
          "title": "5C Counterhit Corner",
          "notation": "5Cx15 (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 5[B] > AOA~D",
          "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it for huge damage.",
          "clip": "https://www.youtube.com/embed/Yg1NxwsRrPk",
          "key": 224
      },
  ],
  
  jumpC: [
      {
          "title": "j.C Counterhit",
          "notation": "j.C (Counter) > 4B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "j.C is mostly used as a throw tech bait, but due to the option select, isn't used as often. However, j.C does not trigger things such as Kanji's counter DP so it double baits his throw and DP.",
          "clip": "https://www.youtube.com/embed/pA8Jiucu8NE",
          "key": 224
      },
      {
        "title": "j.C Counterhit Corner",
        "notation": "j.C (Counter) > 5AA > 5[B] > Sweep > B Coup > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D",
        "description": "Shadow Mitsuru has a very easy time picking up j.C in the corner into the general corner route.",
        "clip": "https://www.youtube.com/embed/0YI-qgsAxM4",
        "key": 273
      },
  ],

  fiveD: [
      {
          "title": "5D Starter",
          "notation": "5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "Really solid low risk long range poke that can also be used as a safe meaty. Follows the general midscreen route.",
          "clip": "https://www.youtube.com/embed/y9A_sabE1LE",
          "key": 225
      },
  ],

  twoD: [
      {
          "title": "2D Anti Air",
          "notation": "2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "This starter is amazing at neutral for catching early jump approaches or sniping zoners out of the sky. It follows the general midscreen route, nothing fancy.",
          "clip": "https://www.youtube.com/embed/4jyYFsO7Cew",
          "key": 222
      },
  ],

  jumpD: [
    {
      "title": "j.D Far",
      "notation": "j.D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
      "description": "If you hit a far range j.D, you can go straight into the general midscreen route with no adjustment.",
      "clip": "https://www.youtube.com/embed/epqupBCXv_g",
      "key": 276
    },
    {
      "title": "j.D Close",
      "notation": "j.D~D > Walk Forward > 5AA > 5B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
      "description": "Thanks to Shadow Mitsuru's fast 5AA, we can pick up a full combo and side swap off of close j.D",
      "clip": "https://www.youtube.com/embed/s0gOQYBJE08",
      "key": 277
    },
    {
      "title": "j.D Corner Swap",
      "notation": "j.D~D > Walk Forward > 5AA > 5B > Sweep > B Coup Droit > 2A > 2B > C Bufula > D Bufula > 6[B] > AOA~D",
      "description": "Corner version of the previous combo. Side swaps.",
      "clip": "https://www.youtube.com/embed/KKXLJxVGjSA",
      "key": 278
    },
  ],

  sweep: [

      {
        "title": "Throw",
        "notation": "Throw(hold 4 during animation) > 5AA > 4B > B Coup Droit > 2A > 2B > C Bufula > D Bufula > 5[B] > AOA~D",
        "description": "New throw combo for max damage when not planning to spend frenzy.",
        "clip": "https://youtube.com/embed/m6koJH7_9r8",
        "key": 2801
      },

      {
        "title": "Side Swap Route",
        "notation": "X > 5AA > 2[B]> 6[B]> Sweep > C Bufula > Microdash > Forward Jump > Air Turn > j.[B] > j.C > C Bufula > 5[B] > AOA~D",
        "description": "Side swap route when doing a midscreen launch confirm with your back to the wall.",
        "clip": "https://youtube.com/embed/sSuRf5VDr90",
        "key": 2804
      },

      {
        "title": "5A vs Mitsuru Only",
        "notation": "5AA > 2[B] > 6[B] > Sweep > B Coup Droit > 2A > 2B > 2D > 5[B] > C Bufula > 6[B] > D Bufula > AOA~D ",
        "description": "Here's something annoying. Our general corner route doesn't work on ourself. Use this one instead.",
        "clip": "https://www.youtube.com/embed/T5m1MLJ8_bU",
        "key": 280
      },
      {
        "title": "Mitsuru Specific Corner Route",
        "notation": "5AA > 2[B] > 6[B] > Sweep > B Coup Droit > 2A > 2B > 2D > 5[B] > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "If you get a nicer starter than 5A, you can extend the previous sequence a little bit more.",
        "clip": "https://www.youtube.com/embed/kgZsIaS2mbc",
        "key": 281
      },
      {
        "title": "DP Combo",
        "notation": "DP(Counterhit) > C Bufudyne > B Coup Droit Level 2 ",
        "description": "Hey, it is what it is.",
        "clip": "https://www.youtube.com/embed/MoSSVi_XIdg",
        "key": 282
      },
      {
        "title": "j.A Counterhit",
        "notation": "j.A(Counter) > (5A) > 2B > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "Probably our most difficult counterhit confirm. We can get a full combo from a j.A. the 5A can be omitted if the first hit is low to the ground.",
        "clip": "https://www.youtube.com/embed/hd9NTKkelxE",
        "key": 283
      },
      {
        "title": "Counterhit Sweep Corner",
        "notation": "Sweep (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
        "description": "On sweep counterhit, we can go into the general corner route without the use of any meter. Very huge for low profiling. If you confirm it late, you can spend the meter on SB coup for a last-moment confirm.",
        "clip": "https://www.youtube.com/embed/IZqJHUT3xIw",
        "key": 226
      },
      {
        "title": "Counterhit Air Throw",
        "notation": "Air Throw > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > AOA~D",
        "description": "Much like our regular throw, our air throw prorates really hard. The initial throw itself, however, does tons of damage alone.",
        "clip": "https://www.youtube.com/embed/3LVpuMDUjwM",
        "key": 226
    },
  ],

  counter: [

      {
          "title": "A Coup Counterhit Midscreen",
          "notation": "A Coup (Counter) > 66 > 5AA > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "If I had to name the biggest advantage Shadow Mitsuru has over Normal Mitsuru, it's the ability to get a full conversion from counterhit A Coup anywhere. This will be your most common punish, LEARN IT.",
          "clip": "https://www.youtube.com/embed/gvepjtjCXng",
          "key": 23033
      },
      {
        "title": "A Coup Counterhit Corner",
        "notation": "A Coup (Counter) > 66 > 5AA > 2[B] > 6[B] > Sweep > B Coup > 2A > 2B > C Bufula > D Bufula > 6[B] > AOA~D",
        "description": "Similar to the previous, but in the corner. We do not follow the general corner route due to high untechable time proration from the A Coup starter.",
        "clip": "https://www.youtube.com/embed/hQQYfTYcRLo",
        "key": 230
      },
      {
        "title": "j.C Counterhit",
        "notation": "j.C (Counter) > 4B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
        "description": "j.C is mostly used as a throw tech bait, but due to the option select, isn't used as often. However, j.C does not trigger things such as Kanji's counter DP so it double baits his throw and DP.",
        "clip": "https://www.youtube.com/embed/pA8Jiucu8NE",
        "key": 290
      },
      {
        "title": "j.C Counterhit Corner",
        "notation": "j.C (Counter) > 5AA > 5[B] > Sweep > B Coup > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D",
        "description": "Shadow Mitsuru has a very easy time picking up j.C in the corner into the general corner route.",
        "clip": "https://www.youtube.com/embed/0YI-qgsAxM4",
        "key": 291
      },
      {
        "title": "5C Counterhit Midscreen",
        "notation": "5Cx15 (Counter) > 5D~D > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
        "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it.",
        "clip": "https://www.youtube.com/embed/6qTGP9yncN4",
        "key": 292
    },
    {
        "title": "5C Counterhit Corner",
        "notation": "5Cx15 (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 5[B] > AOA~D",
        "description": "5C Counterhit can be spaced to catch people who want to use quick escape in your pressure. This route will allow you to confirm it for huge damage.",
        "clip": "https://www.youtube.com/embed/Yg1NxwsRrPk",
        "key": 293
    },
      {
          "title": "Counterhit Sweep Corner",
          "notation": "Sweep (Counter) > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > D Bufula > 5[B] > AOA~D ",
          "description": "On sweep counterhit, we can go into the general corner route without the use of any meter. Very huge for low profiling. If you confirm it late, you can spend the meter on SB coup for a last-moment confirm.",
          "clip": "https://www.youtube.com/embed/IZqJHUT3xIw",
          "key": 294
      },
      {
        "title": "2B Counterhit Safe Confirm",
        "notation": "2B > 7 > j.D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "Since 2B is jump cancelable on block, we often use that jump cancel for additional mixup or to quickly block and bait something out. This is how you can pick up an unexpected counterhit during your safe play. The j.D will pick up pretty late, give it a try.",
        "clip": "https://www.youtube.com/embed/GAS2LwqzQAw",
        "key": 295
    },
    {
        "title": "2B Counterhit Feint Sweep",
        "notation": "2B > Feint Sweep > 5AA > 6B > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
        "description": "Sometimes people are mashing and get counterhit by your 2B. This is a potential way you can still pick up that unpredictable counterhit for a full combo thanks to shadow mitsuru's fast 5AA.",
        "clip": "https://www.youtube.com/embed/7j7cPwi0iXQ",
        "key": 296
    },
    {
        "title": "2B Anti Air Corner",
        "notation": "2B > Feint Sweep > 5AA > 6B > Sweep > B Coup Droit > 2A > 2B > 2D > 5[B]4 > Sweep > C Bufula > 6[B] > AOA~D ",
        "description": "Similar to the previous, we can take advantage of Shadow Mitsuru's fast 5AA to pick up a corner combo off of a high anti air where a 2D pickup would be way too high.",
        "clip": "https://www.youtube.com/embed/_8ATgFJXE1U",
        "key": 297
    },
    {
      "title": "j.A Counterhit",
      "notation": "j.A(Counter) > (5A) > 2B > 2D~D > 4[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D ",
      "description": "Probably our most difficult counterhit confirm. We can get a full combo from a j.A. the 5A can be omitted if the first hit is low to the ground.",
      "clip": "https://www.youtube.com/embed/hd9NTKkelxE",
      "key": 298
    },
    {
      "title": "5A Counter Corner",
      "notation": "5A (Counter) > Sweep > SB Coup Droit > 2A > 2B > 2D > 5[B]4 > Delay Sweep > C Bufula > 5[B] > AOA~D ",
      "description": "On counter hit, Mitsuru's 5A combos directly into sweep. This is very valuable as you will be checking at long range with 5A often in your gameplay. Once you get the Sweep, you follow the general corner route. The 5[B]4 is not a typo and means you need to input the B button at neutral and then hold back afterward to get the correct spacing. As with normal Mitsuru, you can add a B coup before the SB coup for extra damage on certain characters.",
      "clip": "https://www.youtube.com/embed/_CMeM927G9k",
      "key": 299
    },
  ],

  meter: [
      {
          "title": "2A Standing 50 Meter",
          "notation": "2A > 2B > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > AOA~D",
          "description": "With 50 meter we can turn our 2A throw tech OS bait into a full corner carry combo. Note that you must omit the final 6[B] of the general midscreen route.",
          "clip": "https://www.youtube.com/embed/rv4JECLzoOA",
          "key": 238
      },
      {
          "title": "5A Standing 50 Meter ",
          "notation": "5AA > 6B > 6OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "With 50 meter, mitsuru can combo 6B into 2[B] using a One More Cancel. After the launch you will use the general midscreen route. Use this if you have a lot of resources and need the extra damage or corner carry!",
          "clip": "https://www.youtube.com/embed/2V8qn1H-CWY",
          "key": 239
      },
      {
          "title": "Midscreen 50 Meter Crouch Confirm Route ",
          "notation": "X > 5B > Sweep > OMC > 2[B] > 6[B] > Sweep > C Bufula > 6[B] > 2[B] > D Bufula > 6[B] > AOA~D",
          "description": "On crouch confirms that go into 5B, you can spend 50 meter to extend straight into the general midscreen route.",
          "clip": "https://www.youtube.com/embed/xK4en71muBs",
          "key": 240
      },
      {
          "title": "Midscreen 50 Meter Crouch Confirm Alternate ",
          "notation": "X > 5B > Sweep > OMC > 2[B] > 2D~D >  4[B] > Sweep > C Bufula > 6[B] > AOA~D",
          "description": "At some spacings, the previous combo will drop, this version sacrfices some damage in order to cover more spacings.",
          "clip": "https://www.youtube.com/embed/2hhA_oJ8Fto",
          "key": 241
      },
      {
          "title": "Awakening Corner Meter Dump",
          "notation": "Corner Combo > AOA~D > D Bufula > Tentarafoo > Mabufudyne > B Myriad Arrows (Fully Charged) > B Myriad Arrows (Fully Charged)",
          "description": "Normal Mitsuru can go into the famed shadow mitsuru sequence when in awakening. From any corner route, do this ender instead. In order for it to work, you must have only frozen the opponent twice before the AOA. Remember that 2D counts as a freeze! With 100 meter, you can do one full charged Myriad as well.",
          "clip": "https://www.youtube.com/embed/xwywiHbdG24",
          "key": 242
      },
      {
          "title": "Awakening Midscreen Meter Dump",
          "notation": "General Midscreen Route until D Bufula > Tentarafoo > Mabufudyne > B Myriad Arrows (Fully Charged) > B Myriad Arrows (Fully Charged)",
          "description": "Mitsuru has access to the famed shadow mitsuru sequence at midscreen as well. Instead of finishing the general midscreen route, cut it early with tentarafoo.",
          "clip": "https://www.youtube.com/embed/WUet7MQe5ow",
          "key": 243
      },
  ],

  frenzy: [
      {
          "title": "X Into Autocombo",
          "notation": " X > Autocombo > Frenzy > B Coup Droit > D Tentarafoo > Mabufudyne > 6[B] > Sweep > A Coup Droit+Ice Shard > B Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Shard > Full Kick ",
          "description": "The most fundamental, and most common frenzy confirm you will have in a real match. Anything that goes into autocombo will confirm this way. At midscreen, we have to opt for a B myriad first instead of SB even with 100 meter.",
          "clip": "https://www.youtube.com/embed/IzEuzP29oO0",
          "key": 300
      },
      {
          "title": "5A Counterhit",
          "notation": "5A(Counter) > Sweep > B Coup Droit > Frenzy > D Tentarafoo > Mabufudyne > 6[B] > Sweep > A Coup Droit+Ice Shard > SB Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Shard > Full Kick ",
          "description": "Your 2nd most common win confirm. 5A counterhit combos into sweep, which combos into the rest. Most opponents will not realize they are dead and you can often steal rounds while they have a burst with this. On routes where we B Coup BEFORE frenzy, we do double SB Myriad Arrows with 100 meter. Keep that in mind.",
          "clip": "https://www.youtube.com/embed/TDU7fQG7c94",
          "key": 301
      },
      {
        "title": "Crouch Confirm",
        "notation": "X > 5B > Sweep > B Coup Droit > Frenzy > D Tentarafoo > Mabufudyne > 6[B] > Sweep > A Coup Droit+Ice Shard > SB Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Shard > Full Kick ",
        "description": "Same pathing as the previous. That old weak crouch combo we hate on Normal Mitsuru is very disireable here over autocombo. If you've been paying attention, this confirm allows us to do double SB Myriad Arrows due to the B Coup being before the frenzy, and autocombo does not allow that.",
        "clip": "https://www.youtube.com/embed/TjjUJ8Dds-o",
        "key": 302
      },
      {
        "title": "General Midscreen Route Into Full Frenzy",
        "notation": "Any general midscreen route > B Coup Droit > Frenzy > D Tentarafoo > Mabufudyne > 6[B] > Sweep > A Coup Droit+Ice Shard > SB Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Shard > Full Kick ",
        "description": "A somewhat common confirm. You'll opt to go into frenzy this way if you have full meter, and will just barely kill with the damage output of this path. Coming up, I'll show and explain other paths.",
        "clip": "https://www.youtube.com/embed/nILOYTaQe4Y",
        "key": 303
      },
      {
        "title": "Midscreen Burst Conservation Route",
        "notation": "General Midscreen Route until D Bufula > D Tentarafoo > Mabufudyne > 66 > Shadow Frenzy > A Myriad Arrows+Ice Shard > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Ice Shard > Full Kick",
        "description": "This route sacrifices some damage to conserve our burst meter. A raw Shadow Frenzy outside of a combo uses 75% of the burst instead of all of it, and by the end of the combo, we have about half of our burst remaining. Please pay attention to the notation, as we use A myriad arrows here and the timing is TIGHT. You should ALWAYS pick this path if it will kill.",
        "clip": "https://www.youtube.com/embed/eqrZLOIDenE",
        "key": 304
      },

      {
        "title": "Throw Corner",
        "notation": "Throw > 5AA > 4B > Sweep > C Bufula > 5[B] > Sweep > B Coup Droit > Frenzy > D Tentarafoo > Mabufudyne > 6[B] > Sweep > A Coup Droit+Ice Shard > SB Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Shard > Full Kick",
        "description": "New throw combo for max damage when spending a full meter frenzy.",
        "clip": "https://youtube.com/embed/YLGnszlHK5U",
        "key": 2802
      },

      {
        "title": "Sub 100 meter conversion #1",
        "notation": "Check video and description.",
        "description": "There will be cases where you get a confirm but will not have 100 meter. This confirm can STILL be the game winning confirm if you know what you are doing. Watch the video closely. Frenzy is activated at 89 meter with the opponent flying to full screen. There is enough time to get A Myriad Arrows at 51 meter, which is just enough to chain into SB Myriad Arrows. These situations are tough to learn, and mandatory for the character.",
        "clip": "https://www.youtube.com/embed/gjS5vjLnkhI",
        "key": 305
      },
      {
        "title": "~50 Meter Midscreen Conversion",
        "notation": "Check video and description. ",
        "description": "In this example, we use the instant overhead pickup with 50 meter, but we can still go into a shadow frenzy combo. You'll use extra filler since we only get one myriad, and you'll use SB myriad every time. This will kill opponents from about 60% hp. While I have supplied two examples on this page, there are many more situations where you can convert without 100 meter from midscreen.",
        "clip": "https://www.youtube.com/embed/XK0httYsYAY",
        "key": 306
      },
      

      {
        "title": "Early Activation",
        "notation": "Check video and description. ",
        "description": "Many times, we pick up a potential game winning confirm while our opponent has a burst. In this version of p4u, shadow frenzy LOCKS our opponent's burst. This video provides two examples of activating shadow frenzy very early before your opponent can react or burst in order to steal a round. The first example, as a bonus, uses autocombo AFTER activation. Although you can't tell due to the training mode settings, autocombo has a bonus property of restoring a chunk of burst meter, so go for it if it's not last round!",
        "clip": "https://www.youtube.com/embed/aEEilu6przY",
        "key": 307
      },

      {
        "title": "Corner Frenzy Route, Full Frenzy",
        "notation": "Corner Combo until AOA~D > D Bufula > D Tentarafoo > Frenzy > Mabufudyne > SB Myriad Arrows > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Ice Shard > Full Kick",
        "description": "This is the way you will end corner combos if you want to get maximum damage out of your shadow frenzy at the cost of 100 meter and your full burst.",
        "clip": "https://www.youtube.com/embed/JSGqEMSymnI",
        "key": 308
      },

      {
        "title": "Corner Frenzy Route, Burst Conservation",
        "notation": "Corner Combo until AOA~D > D Bufula > D Tentarafoo > Mabufudyne > Frenzy > A Myriad Arrows+Ice Shard > Ice Shard > Full Kick > Ice Shard > SB Myriad Arrows > D Ice Shard > Full Kick",
        "description": "Follows the same philosophy as its midscreen cousin. Use this route to keep half of your burst guage any time it will kill.",
        "clip": "https://www.youtube.com/embed/6zUXQGhTRX0",
        "key": 309
      },

      {
        "title": "Corner Frenzy Route, 75+ meter",
        "notation": "Check video and description. ",
        "description": "With about 75 meter, and exclusively on the corner combo route, you can do a double myriad sequence using A Myriad Arrows first.",
        "clip": "https://www.youtube.com/embed/XeX8VnpfgLk",
        "key": 310
      },

      {
        "title": "Raw Shadow Frenzy",
        "notation": "Check video and description. ",
        "description": "Raw Frenzy is completely invincible on startup. This allows it to be used to beat throws, and more importantly, certain blockstrings (Minazuki's persona attack strings for example). This isn't the only conversion, and there are tons of defensive situations, but it does work. This is likely the deepest use of frenzy and the most unexplored depth of Shadow Mitsuru, even years later.",
        "clip": "https://www.youtube.com/embed/07i7EGuna20",
        "key": 311
      },
      {
        "title": "Raw Myriad Arrows",
        "notation": "Check video and description. ",
        "description": "You can get a shadow frenzy conversion from a reversal super. If you do this in a match, it cements you as a big enough bully to play this character, so congratulations.",
        "clip": "https://www.youtube.com/embed/NoEurz_g7f8",
        "key": 312
      },

  ]
  

}

export default ShadowMCombos;