import React, { useState, useEffect } from 'react';
import "./ShadowCombos.css";
import ComboList from "../components/ComboList.jsx";
import Mits from "../images/normalmitsuru.png";
import Combos from "../data/NormalMitsuru.js";

function MitsuruCombos() {
  const[combos, setCombos] = useState([]);
  const[title, setTitle] = useState("");
  useEffect(()=>{ 

    setCombos([]);
  
  },[]);
  return (
    <div>
        <h2>Mitsuru Combos</h2>
        <img src={Mits} alt=""/>
        <div className="selection">
        
            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 5A");
                setCombos(Combos.fiveA);
            }    
            }>5A</button>
            
            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 2A");
                setCombos(Combos.twoA);
                
            }    
            }>2A</button>
            
            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 5B");
                setCombos(Combos.fiveB);
                
            }    
            }>5B</button>

            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 2B");
                setCombos(Combos.twoB);
                
            }    
            }>2B</button>
            
            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from j.B");
                setCombos(Combos.jumpB);
                
            }    
            }>j.B</button>

            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 5C");
                setCombos(Combos.fiveC);
                
            }    
            }>5C</button>

            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from j.C");
                setCombos(Combos.jumpC);
                
            }    
            }>j.C</button>

            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 5D");
                setCombos(Combos.fiveD);
                
            }    
            }>5D</button>
            
            <button onClick={() => {
                setCombos([]);
                setTitle("Combos from 2D");
                setCombos(Combos.twoD);
                
            }    
            }>2D</button>


            <button onClick={() => {
                setCombos([]);
                setTitle("ETC Combos");
                setCombos(Combos.sweep);
                
            }    
            }>ETC</button>
            

            <button onClick={() => {
                setCombos([]);
                setTitle("Counter-hit Combos");
                setCombos(Combos.counter);
                
            }    
            }>Counter-hit Combos</button>

            <button onClick={() => {
                setCombos([]);
                setTitle("Meter Usage Combos");
                setCombos(Combos.meter);
                
            }    
            }>Meter Usage Combos</button>


            <button onClick={() => {
                setCombos([]);
                setTitle("OMB Combos");
                setCombos(Combos.OMB);
                
            }    
            }>OMB</button>
            

            
        </div>
        <div className="player">
            <ComboList combos={combos} title={title} resetPage={true}/>
        </div>
    </div>
  )
}

export default MitsuruCombos;
