import React, { useState, useEffect } from 'react'
import { Videos } from '../data/videos.js';
import VideoPlayer from '../components/VideoPlayer';
import Mits from '../images/normalmitsuru.png';
import Portraits from '../data/portraits.js';
import "./matchvideos.css";

function MitsuruMatchVideos() {
    const[videos, setVideos] = useState([]);
    const[title, setTitle] = useState("");
    useEffect(()=>{ 
  
      setVideos([]);
    
    },[]);
    return (
      <div>
          <h2>Mitsuru Match Videos</h2>
          <img src={Mits} alt=""/>
          <div className="player">
            <VideoPlayer videos={videos} title={title}/>
          </div>
          <h4>Click on a character to view match videos!</h4>
          <div className="video-selection">
          

              <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Adachi");
                  setVideos(Videos.Adachi);
                  
              }    
              }><img src={Portraits.adachi} alt=""/></button>

              <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Aigis");
                  setVideos(Videos.Aigis);
              }    
              }><img src={Portraits.aig} alt=""/></button>

                <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Aigis");
                  setVideos(Videos.AigisShadow);
              }    
              }><img src={Portraits.aigshadow} alt=""/></button>              
  
              <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Akihiko");
                  setVideos(Videos.Akihiko);
                  
              }    
              }><img src={Portraits.aki} alt=""/></button>

              <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Akihiko");
                  setVideos(Videos.AkihikoShadow);
                  
              }    
              }><img src={Portraits.akishadow} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Chie");
                  setVideos(Videos.Chie);
                  
              }    
              }><img src={Portraits.chie} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Chie");
                  setVideos(Videos.ChieShadow);
                  
              }    
              }><img src={Portraits.chieshadow} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Elizabeth");
                  setVideos(Videos.Elizabeth);
                  
              }    
              }><img src={Portraits.elizabeth} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Junpei");
                  setVideos(Videos.Junpei);
                  
              }    
              }><img src={Portraits.junpei} alt=""/></button>


            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Junpei");
                  setVideos(Videos.JunpeiShadow);
                  
              }    
              }><img src={Portraits.junpeishadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Kanji");
                  setVideos(Videos.Kanji);
                  
              }    
              }><img src={Portraits.kanji} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Kanji");
                  setVideos(Videos.KanjiShadow);
                  
              }    
              }><img src={Portraits.kanjishadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Ken");
                  setVideos(Videos.Ken);
                  
              }    
              }><img src={Portraits.ken} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Ken");
                  setVideos(Videos.KenShadow);
                  
              }    
              }><img src={Portraits.kenshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Labrys");
                  setVideos(Videos.Labrys);
                  
              }    
              }><img src={Portraits.labrys} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Labrys (Fake)");
                  setVideos(Videos.LabrysShadow);
                  
              }    
              }><img src={Portraits.labrysshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Labrys");
                  setVideos(Videos.ShadowLabrys);
                  
              }    
              }><img src={Portraits.shadowlabrys} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Margaret");
                  setVideos(Videos.Margaret);
                  
              }    
              }><img src={Portraits.margaret} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Marie");
                  setVideos(Videos.Marie);
                  
              }    
              }><img src={Portraits.marie} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Minazuki");
                  setVideos(Videos.Minazuki);
                  
              }    
              }><img src={Portraits.minazuki} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Sho");
                  setVideos(Videos.Sho);
                  
              }    
              }><img src={Portraits.sho} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Mitsuru");
                  setVideos(Videos.MitsuruShadow);
                  
              }    
              }><img src={Portraits.mitsurushadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Naoto");
                  setVideos(Videos.Naoto);
                  
              }    
              }><img src={Portraits.naoto} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Naoto");
                  setVideos(Videos.NaotoShadow);
                  
              }    
              }><img src={Portraits.naotoshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Narukami");
                  setVideos(Videos.Narukami);
                  
              }    
              }><img src={Portraits.narukami} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Narukami");
                  setVideos(Videos.NarukamiShadow);
                  
              }    
              }><img src={Portraits.narukamishadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Rise");
                  setVideos(Videos.Rise);
                  
              }    
              }><img src={Portraits.rise} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Rise");
                  setVideos(Videos.RiseShadow);
                  
              }    
              }><img src={Portraits.riseshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Teddie");
                  setVideos(Videos.Teddie);
                  
              }    
              }><img src={Portraits.teddie} alt=""/></button>

            <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Teddie");
                  setVideos(Videos.TeddieShadow);
                  
              }    
              }><img src={Portraits.teddieshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Yosuke");
                  setVideos(Videos.Yosuke);
                  
              }    
              }><img src={Portraits.yosuke} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Yosuke");
                  setVideos(Videos.YosukeShadow);
                  
              }    
              }><img src={Portraits.yosukeshadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Yukari");
                  setVideos(Videos.Yukari);
                  
              }    
              }><img src={Portraits.yukari} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Yukari");
                  setVideos(Videos.YukariShadow);
                  
              }    
              }><img src={Portraits.yukarishadow} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Yukiko");
                  setVideos(Videos.Yukiko);
                  
              }    
              }><img src={Portraits.yukiko} alt=""/></button>
                          <button onClick={() => {
                  setVideos([]);
                  setTitle("Mitsuru vs. Shadow Yukiko");
                  setVideos(Videos.YukikoShadow);
                  
              }    
              }><img src={Portraits.yukikoshadow} alt=""/></button>

              
              

              
              
          </div>

      </div>
    )
}

export default MitsuruMatchVideos;
