import React from 'react'
import "./Combo.css"
import YoutubeEmbed from "./YouTube";

function Combo(props) {
  return (
    <div className="combo">
        <div className="combo-name">
            <h3>{props.combo.title}</h3>
            <p>{props.combo.notation}</p>
        </div>
        <div className="clip">
            <YoutubeEmbed embedId={props.combo.clip} />
        </div>
        <div className="combo-description">
            <p>{props.combo.description}</p>
        </div>
      
    </div>
  )
}

export default Combo;
