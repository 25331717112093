import React from 'react';
import { NavLink } from 'react-router-dom';
import './HomePage.css';
import Normal from '../images/normalmitsuru.png';
import Shadow from '../images/throne.png';
import Logo from '../images/mitsuru-mains-ds.svg';

function HomePage() {
  return (
    <div className="main">
        <div className="logo">
            <img src={Logo} alt=""/>
        </div>
        <div className="selection-box">       
                <h4>Normal Mitsuru</h4>
                <img src={Normal} alt=""/>          
        <NavLink to="/mitsuruguide"><button>Gameplay Guide</button></NavLink>
        <NavLink to="/mitsurucombos"><button>Combos</button></NavLink>
        <NavLink to="/mitsuruvideos"><button>Match Videos</button></NavLink>
        </div>
        <div className="selection-box">           
                <h4>Shadow Mitsuru</h4>
                <img src={Shadow} alt=""/>
        <NavLink to="/shadowguide"><button>Gameplay Guide</button></NavLink>
        <NavLink to="/shadowcombos"><button>Combos</button></NavLink>
        <NavLink to="/shadowvideos"><button>Match Videos</button></NavLink>
        </div>
        <div>
          <h6>© 2022 Cody "T-Loc" Coleman</h6>
        </div>    
    </div>
  )
}

export default HomePage;