const Videos = {
    Adachi: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/a3KoqO86hYM",
            key: 302
        }
    ],

    Aigis: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/p-YVpD3UUoE",
            key: 301
        }
    ],

    AigisShadow: [
        {
            title: "3:03",
            clip: "https://embed.nicovideo.jp/watch/sm27159112?autoplay=0",
            key: 302
        }
    ],

    Akihiko: [
        {
            title: "Jon (Mitsuru) vs. Jomeshi (Akihiko)",
            clip: "https://embed.nicovideo.jp/watch/sm27440956?autoplay=0",
            key: 302
        }

    ],

    AkihikoShadow: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm26151865?autoplay=0",
            key: 302
        }
    ],
    Chie: [],

    ChieShadow: [
        {
            title: "YMST matches",
            clip: "https://www.youtube.com/embed/ZZA80bAvqOQ",
            key: 302
        }
    ],

    Elizabeth: [
        {
            title: "8:00",
            clip: "https://www.youtube.com/embed/ZAz-qJFuxb0",
            key: 302
        }
    ],

    Junpei: [
        {
            title: "Junpei sets at 10:35",
            clip: "https://www.youtube.com/embed/5tsypsyhf1I",
            key: 302
        }
    ],

    JunpeiShadow: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm26623017?autoplay=0",
            key: 302
        }
    ],

    Kanji: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/8xx6zQnkx6E",
            key: 302
        }
    ],

    KanjiShadow: [
        {
            title: "5:12",
            clip: "https://embed.nicovideo.jp/watch/sm27331098?autoplay=0",
            key: 302
        }
    ],

    Ken: [

    ],

    KenShadow: [
        {
            title: "YMST",
            clip: "https://www.youtube.com/embed/8kMHilGrhAw",
            key: 302
        }
    ],

    Labrys: [

    ],

    LabrysShadow: [

    ],

    ShadowLabrys: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm25902072?autoplay=0",
            key: 302
        }
    ],

    Margaret: [
                {
            title: "YMST vs Tahichi",
            clip: "https://www.youtube.com/embed/hSjlrXo4ayw",
            key: 302
        }
    ],

    Marie: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/mP4XGI6AQ1g",
            key: 302
        }
    ],

    Minazuki: [
        {
            title: "YMST long sets with sita, starts at 44:37",
            clip: "https://www.youtube.com/embed/ad0tMbnaDwI",
            key: 302
        }
    ],

    Sho: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/7Bdaa6VgArY",
            key: 302
        }
    ],

    Mitsuru: [
        {
            title: "6:15",
            clip: "https://embed.nicovideo.jp/watch/sm27440956?autoplay=0",
            key: 302
        }
    ],

    Naoto: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm25802596?autoplay=0",
            key: 302
        }
    ],

    NaotoShadow: [
        {
            title: "",
            clip: "https://www.youtube.com/embed/OQqJd_s2y1A&t=896s",
            key: 302
        }
    ],

    Narukami: [
        {
            title: "YMST matches with Akio. Starts at 3:30",
            clip: "https://www.youtube.com/embed/ad0tMbnaDwI",
            key: 302
        }
    ],

    NarukamiShadow: [
        {
            title: "5:00",
            clip: "https://embed.nicovideo.jp/watch/sm26704319?autoplay=0",
            key: 302
        }
    ],

    Rise: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm27331144?autoplay=0",
            key: 302
        }
    ],

    RiseShadow: [

    ],

    Teddie: [
        {
            title: "Pepe long sets",
            clip: "https://www.youtube.com/embed/5tsypsyhf1I",
            key: 302
        }
    ],

    TeddieShadow: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm26883192?autoplay=0",
            key: 302
        },
        {
            title: "YMST vs Mad",
            clip: "https://www.youtube.com/embed/VXcH5KB4ff4",
            key: 302
        }
    ],
    
    Yosuke: [

    ],

    YosukeShadow: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm26623098?autoplay=0",
            key: 302
        }
    ],

    Yukari: [
        {
            title: "Pepe long sets",
            clip: "https://www.youtube.com/embed/qvrE7U0GU9M",
            key: 302
        }
    ],

    YukariShadow: [
        {
            title: "1:43:00",
            clip: "https://www.youtube.com/embed/7ZSOYIYyrdg",
            key: 302
        }
    ],

    Yukiko: [
        {
            title: "5:30",
            clip: "https://embed.nicovideo.jp/watch/sm27635878?autoplay=0",
            key: 302
        }
    ],

    YukikoShadow: [
        {
            title: "",
            clip: "https://embed.nicovideo.jp/watch/sm27331098?autoplay=0",
            key: 302
        }
    ]

}

export default Videos;