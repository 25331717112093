import Adachi from '../images/portraits/adachi.png';
import Aigis from '../images/portraits/aigis.png';
import AigisShadow from '../images/portraits/aigisshadow.png';
import Akihiko from '../images/portraits/akihiko.png';
import AkihikoShadow from '../images/portraits/akihikoshadow.png';
import Chie from '../images/portraits/chie.png';
import ChieShadow from '../images/portraits/chieshadow.png';
import Elizabeth from '../images/portraits/elizabeth.png';
import Junpei from '../images/portraits/junpei.png';
import JunpeiShadow from '../images/portraits/junpeishadow.png';
import Kanji from '../images/portraits/kanji.png';
import KanjiShadow from '../images/portraits/kanjishadow.png';
import Ken from '../images/portraits/ken.png';
import KenShadow from '../images/portraits/kenshadow.png';
import Labrys from '../images/portraits/labrys.png';
import LabrysShadow from '../images/portraits/fakeshadowlabrys.png';
import ShadowLabrys from '../images/portraits/labrysshadow.png';
import Margaret from '../images/portraits/margaret.png';
import Marie from '../images/portraits/marie.png';
import Minazuki from '../images/portraits/minazuki.png';
import Mitsuru from '../images/portraits/mitsuru.png';
import MitsuruShadow from '../images/portraits/mitsurushadow.png';
import Naoto from '../images/portraits/naoto.png';
import NaotoShadow from '../images/portraits/naotoshadow.png';
import Narukami from '../images/portraits/narukami.png';
import NarukamiShadow from '../images/portraits/narukamishadow.png';
import Rise from '../images/portraits/rise.png';
import RiseShadow from '../images/portraits/riseshadow.png';
import Sho from '../images/portraits/sho.png';
import Teddie from '../images/portraits/teddie.png';
import TeddieShadow from '../images/portraits/teddieshadow.png';
import Yosuke from '../images/portraits/yosuke.png';
import YosukeShadow from '../images/portraits/yosukeshadow.png';
import Yukari from '../images/portraits/yukari.png';
import YukariShadow from '../images/portraits/yukarishadow.png';
import Yukiko from '../images/portraits/yukiko.png';
import YukikoShadow from '../images/portraits/yukikoshadow.png';

const Portraits = {
    adachi: Adachi,
    aki: Akihiko,
    akishadow: AkihikoShadow,
    aig: Aigis,
    aigshadow: AigisShadow,
    chie: Chie,
    chieshadow: ChieShadow,
    elizabeth: Elizabeth,
    junpei: Junpei,
    junpeishadow: JunpeiShadow,
    kanji: Kanji,
    kanjishadow: KanjiShadow,
    ken: Ken,
    kenshadow: KenShadow,
    labrys: Labrys,
    labrysshadow: LabrysShadow,
    shadowlabrys: ShadowLabrys,
    margaret: Margaret,
    marie: Marie,
    minazuki: Minazuki,
    mitsuru: Mitsuru,
    mitsurushadow: MitsuruShadow,
    naoto: Naoto,
    naotoshadow: NaotoShadow,
    narukami: Narukami,
    narukamishadow: NarukamiShadow,
    rise: Rise,
    riseshadow: RiseShadow,
    sho: Sho,
    teddie: Teddie,
    teddieshadow: TeddieShadow,
    yosuke: Yosuke,
    yosukeshadow: YosukeShadow,
    yukari: Yukari,
    yukarishadow: YukariShadow,
    yukiko: Yukiko,
    yukikoshadow: YukikoShadow

}

export default Portraits;