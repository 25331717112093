import React, { useState } from 'react';
import "./Pagination.css";

function Pagination({ data, RenderComponent, title, pageLimit, dataLimit,  }) {
    const [pages, setPages] = useState(Math.round(data.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1);
    
    
    function goToNextPage() {
        setCurrentPage((page) => page + 1);
      }

  
    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
      }
  
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
      }
    
  
    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
      };
  
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
      };


      if (data.length > pages) {
        
        setPages(Math.round(data.length / dataLimit));
        setCurrentPage(1);
        
      }

      if (data.length < currentPage) {
        setPages(Math.round(data.length / dataLimit));
        setCurrentPage(1);
      }
  
      return (
        <div>
          
          <h3>{title}</h3>

      
          {/* show the pagiantion
              it consists of next and previous buttons
              along with page numbers, in our case, 5 page
              numbers at a time
          */}
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
            >
              prev
            </button>
      
            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <button
                key={index}
                onClick={changePage}
                className={`paginationItem ${currentPage === item ? 'active' : null}`}
              >
                <span>{item}</span>
              </button>
            ))}
      
            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? 'disabled' : ''}`}
            >
              next
            </button>
          </div>
                    {/* show the posts, 10 posts at a time */}
                    <div className="dataContainer">
            {getPaginatedData().map((d, idx) => (
              <RenderComponent key={idx} combo={d} />
            ))}
          </div>
        </div>
      );
  }

  export default Pagination;